import type { AxiosError, AxiosInstance } from 'axios'
import { useUserStore } from '@/config/store/userStore'
import router from '@/config/router'
import { useApplicationStore } from '@/config/store/applicationStore'

let userStore
let applicationStore
const getUserStore = () => {
  if (!userStore)
    userStore = useUserStore()
  return userStore
}
const getApplicationStore = () => {
  if (!applicationStore)
    applicationStore = useApplicationStore()
  return applicationStore
}

const axiosRequestInterceptor = (axios: AxiosInstance) => {
  // Add a request interceptor
  axios.interceptors.request.use(
    (config) => {
      config.headers.authorization = `Bearer ${getUserStore()?.token}`
      return config
    },
    (error: AxiosError) =>
    // Do something with request error
      Promise.reject(error),
  )
  return axios
}

const axiosResponseInterceptor = (axios: AxiosInstance) => {
  // Add a response interceptor
  axios.interceptors.response.use(
    response =>
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
      response,
    async (error: AxiosError) => {
      if (error?.response?.status === 401) {
        getApplicationStore().pushError({
          title: error?.response?.data?.customMessage ?? 'Session expired',
          message: 'Please login again',
          color: 'warning',
          duration: 5000,
        })
        getUserStore().disconnection()
        await router.push({ name: 'Login' })
        return Promise.reject(error)
      }
      if (error.message === 'Network Error') {
        getApplicationStore().pushError({
          title: 'Connection to the server failed',
          message: 'Please, verify your internet connection and try again.',
          color: 'error',
          duration: 5000,

        })
      }
      if (error?.response?.status === 500) {
        getApplicationStore().pushError({
          title: 'An error occurred on our side',
          message: error.response?.data?.customMessage ?? 'If it\'s blocking you, please contact support.',
          color: 'error',
          duration: 5000,

        })
      }
      if (error.response?.data?.customMessage) {
        getApplicationStore().pushError({
          title: error.response?.data?.customMessage,
          message: error.response?.data?.message ?? 'If it\'s blocking you, please contact support.',
          color: 'error',
          duration: 8000,
        })
      }
      throw error
    },
  )
  return axios
}

export default (axios: AxiosInstance): AxiosInstance => {
  axios = axiosRequestInterceptor(axios)
  axios = axiosResponseInterceptor(axios)

  return axios
}
