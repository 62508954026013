<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useDark } from '@vueuse/core'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import CountryFlag from '@/components/common/CountryFlag.vue'

// import ReloadPrompt from '@/components/application/ReloadPrompt.vue'
import IconExit from '~icons/mdi/exit-run'
import { useUserStore } from '@/config/store/userStore'
import { useApplicationStore } from '@/config/store/applicationStore'
import PhSunDuotone from '~icons/ph/sun-duotone'
import PhMoonStarsDuotone from '~icons/ph/moon-stars-duotone'
import MaterialSymbolsBrowserUpdatedRounded from '~icons/material-symbols/browser-updated-rounded'
import { getDrawerMenu, getDrawerMenuSuperAdmin } from '@/services/application/constantes'
import { APP_VERSION } from '@/config/constantes'
import Burger from '@/components/navigationDrawer/burger.vue'
import MaterialSymbolsLockOutline from '~icons/material-symbols/lock-outline'
import MaterialSymbolsLockOpenOutlineRounded from '~icons/material-symbols/lock-open-outline-rounded'
import IcRoundQuestionMark from '~icons/ic/round-question-mark'
import IcRoundKeyboardArrowLeft from '~icons/ic/round-keyboard-arrow-left'
import MaterialSymbolsAdminPanelSettingsOutlineSharp from '~icons/material-symbols/admin-panel-settings-outline-sharp'
import SearchCommand from '@/components/search/SearchCommand.vue'
import DefaultProfile from '@/assets/svg/profile.svg'

import MenuItem from '@/components/navigationDrawer/MenuItem.vue'
import BButton from '@/components/common/bButton.vue'
import BSelect from '@/components/common/bSelect.vue'
import { resourceApi } from '@/services/api'
import Loading from '@/components/common/loading.vue'
import ThemeSwitcher from '@/components/common/ThemeSwitcher.vue'
import BcPictograme from '@/assets/images/BestConsultant_Pictogramme.svg??url'
import LocalSwitch from '@/components/common/LocalSwitch.vue'
import { guardExport } from '@/config/router/routes'
import SolarDatabaseLineDuotone from '~icons/solar/database-line-duotone'
import SolarCloudDownloadOutline from '~icons/solar/cloud-download-outline'

const {
  offlineReady,
  needRefresh,
  updateServiceWorker,
} = useRegisterSW()

const isDark = useDark()
const route = useRoute()

const imageError = ref(false)

const componentOptions = ref({
  updatingProfile: false,
})

const isSuperAdminRoute = computed(() => {
  return !!route.matched.find(currentRoute => currentRoute.meta.moduleId === 'SUPER_ADMIN')
})

const drawerColor = computed(() => {
  return isSuperAdminRoute.value ? 'bg-base' : 'bg-base'
})

const { t, locale } = useI18n()

const currentCountryImage = computed(() => {
  return `/flag_${locale.value === 'fr' ? 'FR' : 'EN'}.webp`
})

const userStore = useUserStore()
const applicationStore = useApplicationStore()

const isOpen = ref(false)
const showSelect = ref(false)

const updateAccessProfile = (profile) => {
  const indexProfile = userStore.user.availableProfileIds.findIndex(id => id === profile._id)
  const indexProfilePopulated = userStore.user.availableProfiles.findIndex(currentProfile => currentProfile._id === profile._id)
  if (indexProfile === -1 || indexProfilePopulated === -1)
    return
  componentOptions.value.updatingProfile = true
  showSelect.value = false
  userStore.user.availableProfileIds.splice(indexProfile, 1)
  userStore.user.availableProfileIds.push(userStore.user.profile._id)

  userStore.user.availableProfiles.splice(indexProfilePopulated, 1)
  userStore.user.availableProfiles.push(userStore.user.profile)

  // const availableProfileIds = [...userStore.user.availableProfileIds.filter(id => id !== profile._id), userStore.user.profile.accessProfile._id]
  resourceApi.updateResource(userStore.user._id, { accessProfile: profile._id, availableProfileIds: userStore.user.availableProfileIds }).promise.then(() => {
    userStore.user.accessProfile = profile._id
    userStore.user.profile = profile
  }).finally(() => {
    componentOptions.value.updatingProfile = false
  })
}

const availableProfiles = computed(() => {
  return [...userStore.user.availableProfiles, userStore.user.profile]
})

const drawerRef = ref<HTMLElement | null>(null)
</script>

<template>
  <div
    v-if="userStore.user" ref="drawerRef" data-cy="navigation-drawer"
    class="relative top-2 left-2 w-full md:bottom-2 md:fixed transition-all duration-300 z-50 flex flex-col flex-grow card-base p-0 gap-10"
    :class="[drawerColor, applicationStore.drawer.fixed ? 'md:w-64 drawer-container-fixed' : 'md:w-14 md:hover:w-64 drawer-container shadow']"
  >
    <div v-auto-animate class="flex flex-col gap-2">
      <div class="flex items-center pl-2 h-16 md:h-24 relative justify-between md:justify-start">
        <div
          class="rounded-full border-2 border-blue-500 mr-3 overflow-hidden md:h-10 md:w-10 w-8 h-8 aspect-square shrink-0"
          :title="`${userStore.user.firstname} ${userStore.user.lastname}`"
        >
          <img
            v-if="userStore.user.picture && !imageError" width="15px" height="15px" loading="lazy"
            :src="userStore.user.picture" :alt="`Avatar de ${userStore.user.firstname} ${userStore.user.lastname}`"
            class="md:h-10 md:w-10 w-8 h-8" @error="() => imageError = true"
          >
          <DefaultProfile v-else class=" md:h-10 md:w-10 w-8 h-8" />
        </div>
        <div class="menu-item-show-hover md:opacity-0 md:hidden flex-col px-2 gap-2 items-start w-full">
          <BSelect
            v-if="userStore.user?.availableProfileIds?.length > 0 && showSelect" :popover-container="drawerRef"
            class="w-full  shrink-0 min-w-20 grow flex-1" size="mini" :options="availableProfiles"
            :model-value="userStore.user.profile" @update:model-value="updateAccessProfile"
          />
          <span
            v-else
            class="px-2 py-0.5 mt-2 text-xs font-medium tracking-wide text-blue-800 bg-blue-100 rounded-full cursor-pointer line-clamp-1"
            @click="showSelect = true"
          >
            <template v-if="!componentOptions.updatingProfile">
              {{ userStore.user.profile.display }}
            </template>
            <Loading v-else />
          </span>
          <div class="hidden md:flex gap-2 text-xl items-center h-7">
            <span
              title="Lock navigation drawer" class="tracking-wide cursor-pointer text-xl"
              @click="applicationStore.drawer.fixed = !applicationStore.drawer.fixed"
            >
              <template v-if="applicationStore.drawer.fixed">
                <MaterialSymbolsLockOpenOutlineRounded />
              </template>
              <template v-else="">
                <MaterialSymbolsLockOutline />
              </template>
            </span>
            <ThemeSwitcher>
              <template #default="{ isDark, toggle }">
                <span class="tracking-wide cursor-pointer text-xl" @click="toggle">
                  <template v-if="isDark">
                    <PhMoonStarsDuotone />
                  </template>
                  <template v-else>
                    <PhSunDuotone />
                  </template>
                </span>
              </template>
            </ThemeSwitcher>
            <LocalSwitch />
          </div>
        </div>

        <Burger class="mx-4 md:hidden" :is-open="isOpen" @toggle="() => isOpen = !isOpen" />
      </div>
      <BButton v-if="needRefresh" bg="success" class="mx-2" @click="updateServiceWorker">
        <SolarCloudDownloadOutline class="animate-bounce h-5 w-5" />
        <span class="update-button-text whitespace-nowrap">

          Update to the latest version
        </span>
      </BButton>
      <SearchCommand v-if="userStore.user" />
    </div>
    <div
      class="h-0 overflow-hidden translate-y-20 md:translate-y-0 md:h-auto overflow-y-auto w-full z-100 transition-all absolute rounded-b-xl md:rounded-b-none md:rounded-r-xl md:relative duration-500 transform  mini-scroll"
      :class="[{ 'h-[80vh]': isOpen }, drawerColor]"
    >
      <transition
        class="transform-gpu transition-transform" enter-from-class="-translate-x-1/3 opacity-0"
        enter-active-class="transition duration-300" leave-active-class="transition duration-300" mode="out-in"
      >
        <ul v-if="!isSuperAdminRoute" class="flex flex-col pb-4 space-y-2">
          <MenuItem
            v-for="menuItem in getDrawerMenu()" :key="menuItem.moduleId" :menu-item="menuItem"
            @click="(open) => isOpen = open"
          />
          <li class="!mt-10" />
          <li v-if="guardExport() === true">
            <router-link
              data-cy="Export-link" :to="{ name: 'Export' }"
              class="relative flex flex-row items-center h-11 focus:outline-none hover:border-primary-800 hover:bg-primary-800 hover:bg-opacity-25 border-l-4 border-transparent pr-6"
              @click="() => isOpen = false"
            >
              <span class="inline-flex justify-center items-center ml-4">
                <SolarDatabaseLineDuotone />
              </span>
              <span class="ml-2 text-sm tracking-wide truncate">Export datas</span>
            </router-link>
          </li>
          <li v-if="userStore.user.profile.isSubAdminProfile">
            <router-link
              data-cy="Documentation-link" :to="{ name: 'SuperAdminUsers' }"
              class="relative flex flex-row items-center h-11 focus:outline-none hover:border-warning-800 hover:bg-warning-800 hover:bg-opacity-25 border-l-4 border-transparent pr-6"
              @click="() => isOpen = false"
            >
              <span class="inline-flex justify-center items-center ml-4">
                <MaterialSymbolsAdminPanelSettingsOutlineSharp />
              </span>
              <span class="ml-2 text-sm tracking-wide truncate">Application administration</span>
            </router-link>
          </li>
          <li :class="{ '!mt-10': !userStore.user.profile.isSubAdminProfile }">
            <router-link
              data-cy="Documentation-link" :to="{ name: 'DocumentationHome' }"
              class="relative flex flex-row items-center h-11 focus:outline-none  hover:border-warning-400 hover:bg-warning-400 hover:bg-opacity-25 border-l-4 border-transparent pr-6"
              @click="() => isOpen = false"
            >
              <span class="inline-flex justify-center items-center ml-4">
                <IcRoundQuestionMark />
              </span>
              <span class="ml-2 text-sm tracking-wide truncate">Documentation</span>
            </router-link>
          </li>
          <li>
            <router-link
              data-cy="changelog-link" :to="{ name: 'ApplicationChangelog' }"
              class="relative flex flex-row items-center h-11 focus:outline-none   hover:border-warning-800 hover:bg-warning-800 hover:bg-opacity-25 border-l-4 border-transparent pr-6"
              @click="() => isOpen = false"
            >
              <span class="inline-flex justify-center items-center ml-4">
                <MaterialSymbolsBrowserUpdatedRounded />
              </span>
              <span class="ml-2 text-sm tracking-wide truncate">Updates</span>
            </router-link>
          </li>
          <li>
            <router-link
              data-cy="logout-link" :to="{ name: 'Disconnection' }"
              class="relative flex flex-row items-center h-11 focus:outline-none   hover:border-red-800 hover:bg-red-800 hover:bg-opacity-25 border-l-4 border-transparent pr-6"
              @click="() => isOpen = false"
            >
              <span class="inline-flex justify-center items-center ml-4">
                <IconExit />
              </span>
              <span class="ml-2 text-sm tracking-wide truncate">Logout</span>
            </router-link>
          </li>
        </ul>
        <ul v-else class="flex flex-col pb-4 space-y-2">
          <BButton
            :to="{ name: 'Home' }" bg="transparent"
            class="!justify-start gap-2 tracking-wide flex uppercase whitespace-nowrap "
          >
            <span class="inline-flex justify-center items-center mr-2">
              <IcRoundKeyboardArrowLeft />
            </span>
            <p class="menu-item-show-hover md:opacity-0">
              Return to app
            </p>
          </BButton>
          <MenuItem
            v-for="menuItem in getDrawerMenuSuperAdmin()" :menu-item="menuItem"
            @click="(open) => isOpen = open"
          />
        </ul>
      </transition>
      <div class="mb-14 mt-auto px-auto py-3 text-center text-xs whitespace-nowrap flex flex-col items-center">
        <div class="flex md:hidden gap-2 text-xl items-center h-7">
          <span
            title="Lock navigation drawer" data-cy="navigation-drawer-lock"
            class="hidden md:flex tracking-wide cursor-pointer text-xl"
            @click="applicationStore.drawer.fixed = !applicationStore.drawer.fixed"
          >
            <template v-if="applicationStore.drawer.fixed">
              <MaterialSymbolsLockOpenOutlineRounded />
            </template>
            <template v-else>
              <MaterialSymbolsLockOutline />
            </template>
          </span>
          <span class="tracking-wide cursor-pointer text-xl" @click="isDark = !isDark">
            <template v-if="isDark">
              <PhMoonStarsDuotone />
            </template>
            <template v-else>
              <PhSunDuotone />
            </template>
          </span>
          <LocalSwitch />
        </div>
        <span class="my-2 flex flex-col gap-5 items-center">
          <router-link to="/" class="!bg-transparent transition-all" :class="{ 'hover:rotate-90': !userStore.user.firm.isWL }">
            <img class="max-h-10 grow w-auto max-w-full" height="15" width="15" :src="userStore.user.firm.isWL && userStore.user.firm.picture ? userStore.user.firm.picture : BcPictograme" alt="Office country">
          </router-link>
          v{{ APP_VERSION }}
          <!-- <ReloadPrompt /> -->
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.drawer-container:hover .menu-item-show-hover,
.drawer-container-fixed .menu-item-show-hover {
  opacity: 1;
  display: flex;
  transition: all ease 0.5s;
}

.router-link-active {
  /* background-color: rgb(30, 40, 67); */
  /* color: white !important; */
}

@keyframes update-button {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.drawer-container-fixed .update-button-text,
.drawer-container:hover .update-button-text{

  animation: forwards update-button 0.5s;
  opacity: 0;
  display:flex;
}
.update-button-text{
  display: none;
}
</style>
