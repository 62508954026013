import { computed, isProxy, isReactive, isRef, onMounted, onUnmounted, ref, toRaw } from 'vue'
import { useWebWorkerFn } from '@vueuse/core'
import convert from 'color-convert'
import type { IFile } from '@/services/application/interfaces'
import { useApplicationStore } from '@/config/store/applicationStore'

export const matchMimeType = [
  {
    extension: '.aac',
    mimeType: 'audio/aac',
    description: 'AAC audio',
  },
  {
    extension: '.abw',
    mimeType: 'application/x-abiword',
    description: 'AbiWord document',
  },
  {
    extension: '.apng',
    mimeType: 'image/apng',
    description: 'Animated Portable Network Graphics (APNG) image',
  },
  {
    extension: '.arc',
    mimeType: 'application/x-freearc',
    description: 'Archive document (multiple files embedded)',
  },
  {
    extension: '.avif',
    mimeType: 'image/avif',
    description: 'AVIF image',
  },
  {
    extension: '.avi',
    mimeType: 'video/x-msvideo',
    description: 'AVI: Audio Video Interleave',
  },
  {
    extension: '.azw',
    mimeType: 'application/vnd.amazon.ebook',
    description: 'Amazon Kindle eBook format',
  },
  {
    extension: '.bin',
    mimeType: 'application/octet-stream',
    description: 'Any kind of binary data',
  },
  {
    extension: '.bmp',
    mimeType: 'image/bmp',
    description: 'Windows OS/2 Bitmap Graphics',
  },
  {
    extension: '.bz',
    mimeType: 'application/x-bzip',
    description: 'BZip archive',
  },
  {
    extension: '.bz2',
    mimeType: 'application/x-bzip2',
    description: 'BZip2 archive',
  },
  {
    extension: '.cda',
    mimeType: 'application/x-cdf',
    description: 'CD audio',
  },
  {
    extension: '.csh',
    mimeType: 'application/x-csh',
    description: 'C-Shell script',
  },
  {
    extension: '.css',
    mimeType: 'text/css',
    description: 'Cascading Style Sheets (CSS)',
  },
  {
    extension: '.csv',
    mimeType: 'text/csv',
    description: 'Comma-separated values (CSV)',
  },
  {
    extension: '.doc',
    mimeType: 'application/msword',
    description: 'Microsoft Word',
  },
  {
    extension: '.docx',
    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    description: 'Microsoft Word (OpenXML)',
  },
  {
    extension: '.eot',
    mimeType: 'application/vnd.ms-fontobject',
    description: 'MS Embedded OpenType fonts',
  },
  {
    extension: '.epub',
    mimeType: 'application/epub+zip',
    description: 'Electronic publication (EPUB)',
  },
  {
    extension: '.gz',
    mimeType: 'application/gzip',
    description: 'GZip Compressed Archive',
  },
  {
    extension: '.gif',
    mimeType: 'image/gif',
    description: 'Graphics Interchange Format (GIF)',
  },
  {
    extension: '.htm, .html',
    mimeType: 'text/html',
    description: 'HyperText Markup Language (HTML)',
  },
  {
    extension: '.ico',
    mimeType: 'image/vnd.microsoft.icon',
    description: 'Icon format',
  },
  {
    extension: '.ics',
    mimeType: 'text/calendar',
    description: 'iCalendar format',
  },
  {
    extension: '.jar',
    mimeType: 'application/java-archive',
    description: 'Java Archive (JAR)',
  },
  {
    extension: '.jpeg, .jpg',
    mimeType: 'image/jpeg',
    description: 'JPEG images',
  },
  {
    extension: '.js',
    mimeType: 'text/javascript (Specifications: HTML and RFC 9239)',
    description: 'JavaScript',
  },
  {
    extension: '.json',
    mimeType: 'application/json',
    description: 'JSON format',
  },
  {
    extension: '.jsonld',
    mimeType: 'application/ld+json',
    description: 'JSON-LD format',
  },
  {
    extension: '.mid, .midi',
    mimeType: 'audio/midi, audio/x-midi',
    description: 'Musical Instrument Digital Interface (MIDI)',
  },
  {
    extension: '.mjs',
    mimeType: 'text/javascript',
    description: 'JavaScript module',
  },
  {
    extension: '.mp3',
    mimeType: 'audio/mpeg',
    description: 'MP3 audio',
  },
  {
    extension: '.mp4',
    mimeType: 'video/mp4',
    description: 'MP4 video',
  },
  {
    extension: '.mpeg',
    mimeType: 'video/mpeg',
    description: 'MPEG Video',
  },
  {
    extension: '.mpkg',
    mimeType: 'application/vnd.apple.installer+xml',
    description: 'Apple Installer Package',
  },
  {
    extension: '.odp',
    mimeType: 'application/vnd.oasis.opendocument.presentation',
    description: 'OpenDocument presentation document',
  },
  {
    extension: '.ods',
    mimeType: 'application/vnd.oasis.opendocument.spreadsheet',
    description: 'OpenDocument spreadsheet document',
  },
  {
    extension: '.odt',
    mimeType: 'application/vnd.oasis.opendocument.text',
    description: 'OpenDocument text document',
  },
  {
    extension: '.oga',
    mimeType: 'audio/ogg',
    description: 'OGG audio',
  },
  {
    extension: '.ogv',
    mimeType: 'video/ogg',
    description: 'OGG video',
  },
  {
    extension: '.ogx',
    mimeType: 'application/ogg',
    description: 'OGG',
  },
  {
    extension: '.opus',
    mimeType: 'audio/opus',
    description: 'Opus audio',
  },
  {
    extension: '.otf',
    mimeType: 'font/otf',
    description: 'OpenType font',
  },
  {
    extension: '.png',
    mimeType: 'image/png',
    description: 'Portable Network Graphics',
  },
  {
    extension: '.pdf',
    mimeType: 'application/pdf',
    description: 'Adobe Portable Document Format (PDF)',
  },
  {
    extension: '.php',
    mimeType: 'application/x-httpd-php',
    description: 'Hypertext Preprocessor (Personal Home Page)',
  },
  {
    extension: '.ppt',
    mimeType: 'application/vnd.ms-powerpoint',
    description: 'Microsoft PowerPoint',
  },
  {
    extension: '.pptx',
    mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    description: 'Microsoft PowerPoint (OpenXML)',
  },
  {
    extension: '.rar',
    mimeType: 'application/vnd.rar',
    description: 'RAR archive',
  },
  {
    extension: '.rtf',
    mimeType: 'application/rtf',
    description: 'Rich Text Format (RTF)',
  },
  {
    extension: '.sh',
    mimeType: 'application/x-sh',
    description: 'Bourne shell script',
  },
  {
    extension: '.svg',
    mimeType: 'image/svg+xml',
    description: 'Scalable Vector Graphics (SVG)',
  },
  {
    extension: '.tar',
    mimeType: 'application/x-tar',
    description: 'Tape Archive (TAR)',
  },
  {
    extension: '.tif, .tiff',
    mimeType: 'image/tiff',
    description: 'Tagged Image File Format (TIFF)',
  },
  {
    extension: '.ts',
    mimeType: 'video/mp2t',
    description: 'MPEG transport stream',
  },
  {
    extension: '.ttf',
    mimeType: 'font/ttf',
    description: 'TrueType Font',
  },
  {
    extension: '.txt',
    mimeType: 'text/plain',
    description: 'Text, (generally ASCII or ISO 8859-n)',
  },
  {
    extension: '.vsd',
    mimeType: 'application/vnd.visio',
    description: 'Microsoft Visio',
  },
  {
    extension: '.wav',
    mimeType: 'audio/wav',
    description: 'Waveform Audio Format',
  },
  {
    extension: '.weba',
    mimeType: 'audio/webm',
    description: 'WEBM audio',
  },
  {
    extension: '.webm',
    mimeType: 'video/webm',
    description: 'WEBM video',
  },
  {
    extension: '.webp',
    mimeType: 'image/webp',
    description: 'WEBP image',
  },
  {
    extension: '.woff',
    mimeType: 'font/woff',
    description: 'Web Open Font Format (WOFF)',
  },
  {
    extension: '.woff2',
    mimeType: 'font/woff2',
    description: 'Web Open Font Format (WOFF)',
  },
  {
    extension: '.xhtml',
    mimeType: 'application/xhtml+xml',
    description: 'XHTML',
  },
  {
    extension: '.xls',
    mimeType: 'application/vnd.ms-excel',
    description: 'Microsoft Excel',
  },
  {
    extension: '.xlsx',
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    description: 'Microsoft Excel (OpenXML)',
  },
  {
    extension: '.xml',
    mimeType: 'application/xml is recommended as of RFC 7303 (section 4.1), but text/xml is still used sometimes. You can assign a specific MIME type to a file with .xml extension depending on how its contents are meant to be interpreted. For instance, an Atom feed is application/atom+xml, but application/xml serves as a valid default.',
    description: 'XML',
  },
  {
    extension: '.xul',
    mimeType: 'application/vnd.mozilla.xul+xml',
    description: 'XUL',
  },
  {
    extension: '.zip',
    mimeType: 'application/zip',
    description: 'ZIP archive',
  },
  {
    extension: '.3gp',
    mimeType: "video/3gpp; audio/3gpp if it doesn't contain video",
    description: '3GPP audio/video container',
  },
  {
    extension: '.3g2',
    mimeType: "video/3gpp2; audio/3gpp2 if it doesn't contain video",
    description: '3GPP2 audio/video container',
  },
  {
    extension: '.7z',
    mimeType: 'application/x-7z-compressed',
    description: '7-zip archive',
  },
]

export const useBreakpoints = () => {
  const windowWidth = ref(window.innerWidth)

  const onWidthChange = () => (windowWidth.value = window.innerWidth)
  onMounted(() => window.addEventListener('resize', onWidthChange))
  onUnmounted(() => window.removeEventListener('resize', onWidthChange))
  const isMobile = /Mobi/i.test(window.navigator.userAgent)

  const size = computed(() => {
    if (windowWidth.value < 550)
      return 'xs'
    if (windowWidth.value > 549 && windowWidth.value < 1200)
      return 'md'
    if (windowWidth.value > 1199)
      return 'lg'
  })

  const width = computed(() => windowWidth.value)

  return { width, size, isMobile }
}

export const createDebounce = () => {
  let timeout: number | undefined
  return function (fnc: Function, delayMs = 500) {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fnc()
    }, delayMs || 500)
  }
}

export const sortByDate = (property: string) => (a, b) => {
  const bDate = new Date(b[property])
  const aDate = new Date(a[property])
  return bDate.getTime() - aDate.getTime()
}

export const downloadBase64File = (
  fileName: string,
  data: any,
  fileFormat: string,
): void => {
  const linkSource = `data:${fileFormat};base64,${data}`
  const downloadLink = document.createElement('a')
  downloadLink.href = linkSource
  downloadLink.download = fileName
  downloadLink.click()
  downloadLink.remove()
}

// export const downloadFileFromUrl = (
//   fileName: string,
//   path: string,
//   extension = 'pdf',
// ): void => {
//   fetch(path)
//     .then(response => response.blob())
//     .then((blob) => {
//       // Créer un objet URL pour le blob
//       const url = window.URL.createObjectURL(new Blob([blob]))

//       // Créer un élément d'ancre (a) hors du DOM
//       const a = document.createElement('a')
//       a.href = url
//       a.download = `${fileName}.${extension}`

//       // Simuler un clic sur l'élément d'ancre pour déclencher le téléchargement
//       a.click()

//       a.remove()
//     })
//     .catch(error => console.error('Erreur lors du téléchargement du fichier : ', error))
// }

export const imageUrlToBase64 = async (url) => {
  const data = await fetch(url)
  const blob = await data.blob()
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64data = reader.result
      resolve(base64data)
    }
    reader.onerror = reject
  })
}

export const imageToBase64 = (image: HTMLImageElement, fileType = 'image/webp'): IFile => {
  const fileName = image?.src?.split('/')?.pop() || 'image'
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  // Set width and height
  canvas.width = image.width
  canvas.height = image.height
  // Draw the image
  ctx?.drawImage(image, 0, 0)
  const base64 = canvas.toDataURL(fileType)
  return {
    base64,
    fileName,
    fileSize: getStringBytesSize(base64),
    fileType,
  }
}

export const getStringBytesSize = (myString: string): number => {
  return encodeURI(myString).split(/%..|./).length - 1
}

export const filesToBase64 = async (
  files: Array<File>,
): Promise<Array<IFile>> => {
  const { workerFn, workerStatus, workerTerminate } = useWebWorkerFn(
    async (files) => {
      const base64Files: Array<IFile> = []

      const filesPromise = files.map((file: File) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => {
            if (typeof reader.result === 'string') {
              base64Files.push({
                fileName: file.name,
                base64: reader.result,
                fileType: file.type,
                fileSize: file.size,
                fileDescription: file.name,
              })
              resolve(reader.result)
            }
          }
        })
      })
      await Promise.all(filesPromise)
      return base64Files
    },
  )
  return await workerFn(files)
}

export const maxTextSize = (value: string, size: number): string => {
  return value?.substring(0, size) || ''
}

export const getFileSize = (bytes, decimals = 2) => {
  if (bytes === 0)
    return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['O', 'Ko', 'Mo', 'Go', 'To']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${Number.parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

export const updateQuery = async ({ router, route, query }) => {
  return router.replace({ name: route.name || '', query })
}

export const isModuleActivated = (module) => {
  return module.isActive
}
export const isModuleAccess = (module) => {
  return module.isAccess
}

export const findModule = (modules, moduleId: number | string) => {
  return modules.find(module => (typeof module.moduleId === 'number' ? module.moduleId : module.moduleId._id) === moduleId)
}
export const findSubModule = (subModules, moduleId: number) => {
  return subModules.find(module => module._id === moduleId)
}

export const isModuleActivatedAndAccess = (modules, moduleId: number | string, subModuleId: number | undefined) => {
  const module = findModule(modules, moduleId)
  const subModule = subModuleId && findSubModule(module?.subAccess, subModuleId)

  return module && isModuleActivated(module) && isModuleAccess(module) && (!subModuleId || (subModule && isModuleAccess(subModule)))
}
export const isModuleSubscribed = (modules, moduleId: number | string) => {
  const module = findModule(modules, moduleId)
  if (!module)
    return false
  return !module.dateTermination || new Date() < new Date(module.dateTermination)
}

export const canUserAccessModule = (subscribedModules, modules, moduleId: number | string, subModuleId) => {
  return isModuleSubscribed(subscribedModules, moduleId) && isModuleActivatedAndAccess(modules, moduleId, subModuleId)
}

type TModule = { dateTermination: null | Date | string; subscriptionTerminatedOn: null | Date | string; isTrial: boolean }
export const isSubscriptionActive = (subscription: TModule) => {
  const moduleDate = new Date(subscription.dateTermination)
  const today = new Date()

  if (subscription.isTrial)
    return subscription.subscriptionTerminatedOn === null && today <= moduleDate

  return subscription.dateTermination === null || today <= moduleDate
}

type TIsModuleAndActive = TModule & {
  moduleId: number
}

export const isModuleActive = (firm: { subscribedModules: TIsModuleAndActive[] }, moduleId: number) => {
  const module = firm.subscribedModules.find(module => module.moduleId === moduleId)
  if (!module)
    return false
  return isSubscriptionActive(module)
}

export const getUpdatedValues = (oldValues, newValues) => {
  const updatedValues = {}
  for (const key in newValues) {
    if (newValues.hasOwnProperty(key)) {
      const newValue = newValues[key]
      const oldValue = oldValues[key]
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue))
        updatedValues[key] = newValue
    }
  }

  return updatedValues
}

export function deepToRaw<T extends Record<string, any>>(sourceObj: T): T {
  const objectIterator = (input: any): any => {
    if (input instanceof Date)
      return new Date(input)
    if (Array.isArray(input))
      return input.map(item => objectIterator(item))
    if (isRef(input) || isReactive(input) || isProxy(input))
      return objectIterator(toRaw(input))
    if (input && typeof input === 'object') {
      return Object.keys(input).reduce((acc, key) => {
        acc[key as keyof typeof acc] = objectIterator(input[key])
        return acc
      }, {} as T)
    }
    return input
  }

  return objectIterator(sourceObj)
}

/**
 * Tailwind color palette generator
 * https://github.com/javisperez/tailwindcolorshades/blob/master/src/composables/colors.ts
 *
 *
 */

export type Palette = {
  hsl: {
    [key: number]: string
  }
  hex: {
    [key: number]: string
  }
}

const CMY_HUES = [180, 300, 60]
const RGB_HUES = [360, 240, 120, 0]

function hueShift(hues: Array<number>, hue: number, intensity: number) {
  const closestHue = hues.sort((a, b) => (Math.abs(a - hue) - Math.abs(b - hue)))[0]
  const hueShift = closestHue - hue
  return Math.round(intensity * hueShift * 0.5)
}

function lighten(hex: string, intensity: number): string {
  if (!hex)
    return ''

  const [h, s, v] = convert.hex.hsv(hex)
  const hue = h + hueShift(CMY_HUES, h, intensity)
  const saturation = s - Math.round(s * intensity)
  const value = v + Math.round((100 - v) * intensity)

  return `#${convert.hsv.hex([hue, saturation, value])}`
}

function darken(hex: string, intensity: number): string {
  if (!hex)
    return ''

  const inverseIntensity = (1 - intensity)
  const [h, s, v] = convert.hex.hsv(hex)
  const hue = h + hueShift(RGB_HUES, h, inverseIntensity)
  const saturation = s + Math.round((100 - s) * inverseIntensity)
  const value = v - Math.round(v * inverseIntensity)

  return `#${convert.hsv.hex([hue, saturation, value])}`
}

export function isValidHexColorCode(str: string) {
  return /^#([0-9A-Fa-f]{3}){1,2}$/.test(str)
}

export function sixDigitsColorHex(hexColor: string) {
  const hexValue = hexColor.replace('#', '')
  return `#${(hexValue.length === 3 ? hexValue.replace(/(.)/g, '$1$1') : hexValue.padEnd(6, '0'))}`
}

export const updateThemeApplication = (colors) => {
  const root = document.querySelector(':root')
  Object.keys(colors).forEach((key) => {
    root.style.setProperty(`--color-primary-${key}`, `${colors[key][0]}deg ${colors[key][1]}% ${colors[key][2]}%`)
  })
}

export const getColorPalette = (baseColor?: string): Palette | undefined => {
  if (!baseColor)
    return

  const fullColorCode = sixDigitsColorHex(baseColor)

  const response = {
    colors: {
      500: fullColorCode,
    },
  }

  const intensityMap: {
    [key: number]: number
  } = {
    50: 0.95,
    100: 0.9,
    200: 0.75,
    300: 0.6,
    400: 0.3,
    600: 0.9,
    700: 0.75,
    800: 0.6,
    900: 0.45,
    950: 0.29,
  };

  [50, 100, 200, 300, 400].forEach((level) => {
    response.colors[level] = lighten(fullColorCode, intensityMap[level])
  });

  [600, 700, 800, 900, 950].forEach((level) => {
    response.colors[level] = darken(fullColorCode, intensityMap[level])
  })

  const convertedToHsl = Object.keys(response.colors).reduce((acc, key) => {
    acc[key] = convert.hex.hsl(response.colors[key])
    return acc
  }, {})

  return { hsl: convertedToHsl, hex: response.colors }
}

export const setFirmThemeColor = (firm) => {
  const theme = getColorPalette(firm.options?.theme?.color || '#0f6bff')

  const applicationStore = useApplicationStore()
  applicationStore.theme = {
    ...(applicationStore.theme || {}),
    colors: theme,
  }
  updateThemeApplication(theme.hsl)
}
